import { template as template_c152b05b9e9f40e2a1cd5b8a0dfda6eb } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_c152b05b9e9f40e2a1cd5b8a0dfda6eb(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
