import { template as template_802edfe0a686428c82ecbf51cc07dfb5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_802edfe0a686428c82ecbf51cc07dfb5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
