import { template as template_cea58089f9c346ca85c79e5ee389214b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cea58089f9c346ca85c79e5ee389214b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
