import { template as template_f4db74388f544d9fab5d60f7e1c1dd74 } from "@ember/template-compiler";
const FKText = template_f4db74388f544d9fab5d60f7e1c1dd74(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
