import { template as template_4102b71019cf4706bf59e4beb9575db1 } from "@ember/template-compiler";
const FKLabel = template_4102b71019cf4706bf59e4beb9575db1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
